<template>
	<div class="home_box">
		<!-- <div class="home_top">
		  <div class="home_top_left">
			  <img src="../../assets/让学习点燃新希望_01.png" alt="" srcset="" />
		  </div>
		  <div class="home_top_right">
			  注册/登录
		  </div>
	  </div> -->

		<div class="vol-main">
			<div class="vol-top backshow">
				<div class="vol-top-ul">
					<div class="vol-top-ulli1">院校专业</div>
					<div class="vol-top-ulli2">
						<el-input v-model="keyword" maxlength="16" placeholder="搜索专业或院校" @input="setKeyWord"></el-input>
					</div>
					<el-button type="danger" plain @click="search">查看</el-button>
				</div>
				<div class="vol-top-ul">
					<div class="vol-top-ulli1">院校省份</div>
					<div class="vol-top-ullisele">
						<!--选中颜色 onsele -->
						<span :class="{ 'onsele': city.includes(item) }" v-for="item in cityData" @click="setCity(item)">{{item}}</span>
					</div>
				</div>
				<div class="vol-top-ul">
					<div class="vol-top-ulli1">院校标签</div>
					<div class="vol-top-ullisele">
						<!--选中颜色 onsele -->
						<span :class="{ 'onsele': college.includes(item) }" v-for="item in collegeData" @click="setCollege(item)">{{item}}</span>
					</div>
				</div>

				<div class="vol-top-ul">
					<div class="vol-top-ultwo">
						<div class="vol-top-ulli1">计划人数</div>
						<div class="vol-top-ulli3">
							<el-input type="text" v-model="plan_min" placeholder="最少" @change="setPlanMin"></el-input>
						</div>
						<div class="vol-top-ulli3">
							<el-input type="text" v-model="plan_max" placeholder="最多" @change="setPlanMax"></el-input>
						</div>

					</div>
					<div class="vol-top-ultwo">
						<div class="vol-top-ulli1">学费</div>
						<div class="vol-top-ulli3">
							<el-input v-model="tuition_min" placeholder="最少" @change="setTuitionMin"></el-input>
						</div>
						<div class="vol-top-ulli3">
							<el-input v-model="tuition_max" placeholder="最多" @change="setTuitionMax"></el-input>
						</div>
						<!-- <div class="vol-top-ulli4">
							免费
						</div>
						<div class="vol-top-ulli4">
							待定
						</div> -->
					</div>
					<div class="vol-top-ultwo">
						<div class="vol-top-ulli1">分数</div>
						<div class="vol-top-ulli3">
							<el-input type="text" v-model="score_min" placeholder="最小" @change="setScoreMin"></el-input>
						</div>
						<div class="vol-top-ulli3">
							<el-input type="text" v-model="score_max" placeholder="最大" @change="setScoreMax"></el-input>
						</div>
					</div>
				</div>
				<div class="vol-top-ul">
					<div class="vol-top-ulli1">风险等级</div>
					<div class="vol-top-ullisele">
						<!--选中颜色 onsele -->
						<span :class="{ 'onsele':(risk - 1) == index  }" v-for="(item,index) in riskData" @click="setRisk(index)">{{item.name}}</span>
					</div>
				</div>

			</div>

			<div class="vol-cen">
				<div class="vol-cen-bo">
					<div class="vol-cen-l">
						<div class="vol-cen-l-btn1">{{ total }}个专业+院校</div>
						<div class="vol-cen-l-btn2" @click="recommendClick"><img src="../../assets/img/tj.png" alt="" />推荐说明</div>
					</div>
					<!-- <div class="vol-cen-r">
						<div>推荐范围</div>
						<div style="width: 86%;display: flex;align-items: center;position: relative;">
							<div style="position: absolute;top: -4px;left: 10px;color: #86909C;font-size: 14px;">
								{{slidervalue[0]}}
							</div>
							<el-slider style="width: 100%;" v-model="slidervalue" range show-stops ref="slider" :max="750">
							</el-slider>
							<div style="position: absolute;top: 0;right: 10px;color: #86909C;font-size: 14px;">
								{{slidervalue[1]}}
							</div>
						</div>
					</div> -->
				</div>

				<div style="height: 48px;">
					<div class="tzy-container-th">
						<div class="pl20" style="width: 10%;">
							录取概率
							<!-- <span class="yzy-table-filter">
								<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									p-id="7065" class="icon" t="1678771182907">
									<path
										d="M904.832 734.165333l-176.917333 176.917334c-40.32 40.32-109.248 11.754667-109.248-45.248V106.666667h85.333333v707.669333l140.501333-140.501333 60.330667 60.330666z"
										fill="#86909C" p-id="7066"></path>
									<path
										d="M119.168 289.834667l176.917333-176.917334C336.405333 72.597333 405.333333 101.162667 405.333333 158.165333V917.333333h-85.333333V209.664l-140.501333 140.501333-60.330667-60.330666z"
										fill="#86909C" p-id="7067"></path>
								</svg>
							</span> -->
						</div>
						<div style="width: 15%; text-align: left; padding-left: 20px;">
							专业

						</div>
						<div style="width: 18.1%; text-align: left; padding-left: 20px;">
							院校
							<!-- <span class="yzy-table-filter">
								<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
									p-id="7065" class="icon" t="1678771182907">
									<path
										d="M904.832 734.165333l-176.917333 176.917334c-40.32 40.32-109.248 11.754667-109.248-45.248V106.666667h85.333333v707.669333l140.501333-140.501333 60.330667 60.330666z"
										fill="#86909C" p-id="7066"></path>
									<path
										d="M119.168 289.834667l176.917333-176.917334C336.405333 72.597333 405.333333 101.162667 405.333333 158.165333V917.333333h-85.333333V209.664l-140.501333 140.501333-60.330667-60.330666z"
										fill="#86909C" p-id="7067"></path>
								</svg>
							</span> -->
						</div>
						<div style="width: 11.2%;">
							{{year}}年招生计划
						</div>
						<div class="tzy-history-tr" style="width: 36.8%;">
							<div class="tzy-container-td " style="text-align:center">历年</div>
							<!-- <div class="tzy-container-td">
								2023
								<span class="yzy-table-filter yzy-table-filterNew">
									<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
										p-id="7065" class="icon" t="1678771182907">
										<path
											d="M904.832 734.165333l-176.917333 176.917334c-40.32 40.32-109.248 11.754667-109.248-45.248V106.666667h85.333333v707.669333l140.501333-140.501333 60.330667 60.330666z"
											fill="#86909C" p-id="7066"></path>
										<path
											d="M119.168 289.834667l176.917333-176.917334C336.405333 72.597333 405.333333 101.162667 405.333333 158.165333V917.333333h-85.333333V209.664l-140.501333 140.501333-60.330667-60.330666z"
											fill="#86909C" p-id="7067"></path>
									</svg>
								</span>
							</div>
							<div class="tzy-container-td">
								2022
								<span class="yzy-table-filter yzy-table-filterNew">
									<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
										p-id="7065" class="icon" t="1678771182907">
										<path
											d="M904.832 734.165333l-176.917333 176.917334c-40.32 40.32-109.248 11.754667-109.248-45.248V106.666667h85.333333v707.669333l140.501333-140.501333 60.330667 60.330666z"
											fill="#86909C" p-id="7066"></path>
										<path
											d="M119.168 289.834667l176.917333-176.917334C336.405333 72.597333 405.333333 101.162667 405.333333 158.165333V917.333333h-85.333333V209.664l-140.501333 140.501333-60.330667-60.330666z"
											fill="#86909C" p-id="7067"></path>
									</svg>
								</span>
							</div>
							<div class="tzy-container-td">
								2021
								<span class="yzy-table-filter yzy-table-filterNew">
									<svg viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
										p-id="7065" class="icon" t="1678771182907">
										<path
											d="M904.832 734.165333l-176.917333 176.917334c-40.32 40.32-109.248 11.754667-109.248-45.248V106.666667h85.333333v707.669333l140.501333-140.501333 60.330667 60.330666z"
											fill="#86909C" p-id="7066"></path>
										<path
											d="M119.168 289.834667l176.917333-176.917334C336.405333 72.597333 405.333333 101.162667 405.333333 158.165333V917.333333h-85.333333V209.664l-140.501333 140.501333-60.330667-60.330666z"
											fill="#86909C" p-id="7067"></path>
									</svg>
								</span>
							</div> -->
						</div>
						<div style="width: 8.9%; text-align: left; padding-left: 20px;">
							填报
						</div>
					</div>
				</div>


				<div>
					<div class="tzy-college-tr" v-for="item in dataList">
						<div class="percent" style="width: 10%;">
							<h1 class="percent-num">
								<span>{{item.risk_percentage}}</span>
								<span style="font-size: 12px;">%</span>
							</h1>
							<!-- <p class="percent-text">极大风险</p> -->
							<p class="ifrush A">
								<span :class="[item.risk_name == '冲'?'span1':'',item.risk_name == '稳'?'span2':'',item.risk_name == '保'?'span3':'']">{{item.risk_name}}</span>
							</p>
							<p class="">预测分：{{item.predictive_score}}</p>
						</div>
						<div class="major" style="width: 15%; text-align: left;">
							<div class="major-info">
								<a class="major-name"><span>{{item.special_name}}</span></a>
								<div class="remark one">{{item.special_remark}}</div>
							</div>
							<p class="major-intr"> 代码： <span class="code">{{item.special_code}}</span> </p>
						</div>
						<div class="college" style="width: 18.1%; text-align: left;">
							<div class="college-info">
								<div>
									<a href="javascript:void(0)" class="college-name" @click="toCollege(item.college_id)">
										<span>{{item.college_name}}</span>
									</a>
									<div class="remark">
										{{item.line_level}} {{item.province}} {{item.city}}
									</div>
								</div>
							</div>
							<div class="college-intr">
								<p> 代码：
									<span class="code"> {{item.college_code}} </span>
									<span v-if="item.tag">
										<span class="split">|</span>
										<span v-for="(t,i) in item.tag" :key="i"> {{i != 0 ? ' / ' + t : t}}</span>
									</span>
									<!-- <span>
										<span class="split">|</span>
										新疆 / 克拉玛依市 / 理工 /
										公办 / 排名 86
									</span> -->
								</p>
							</div>
						</div>
						<div class="plan" style="width: 11.2%;">
							<span class="plan-num"
								style="text-rendering: optimizelegibility; font-feature-settings: &quot;kern&quot;; font-kerning: normal;">
								{{item.plan_num}} <span style="font-size: 12px;">人</span>
							</span>
							<p><span class="learn-year">
									{{item.edu_year}}年&nbsp;&nbsp;￥{{item.tuition}}
								</span> <br> <span class="choose-subject">
									{{item.optional}}
								</span> <span></span></p>
						</div>
						<div class="tzy-history-tr" default-selected="理科" style="width: 36.8%;">
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col"><span>年份</span></div>
								<!-- <div class="tzy-container-td-col"><span>录取人数</span></div>
								<div class="tzy-container-td-col"><span>计划人数</span></div> -->
								<div class="tzy-container-td-col"><span>最低分</span>
								</div>
								<div class="tzy-container-td-col"><span>最低位次</span></div>

							</div>
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col">{{item.year || '-'}}</div>
								<div class="tzy-container-td-col">{{item.predictive_score || '-'}}</div>
								<div class="tzy-container-td-col">{{item.training_low_rank || '-'}}</div>
							</div>
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col">{{item.lv1_year || '-'}}</div>
								<div class="tzy-container-td-col">{{item.lv1_score || '-'}}</div>
								<div class="tzy-container-td-col">{{item.lv1_rank || '-'}}</div>
							</div>
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col">{{item.lv2_year || '-'}}</div>
								<div class="tzy-container-td-col">{{item.lv2_score || '-'}}</div>
								<div class="tzy-container-td-col">{{item.lv2_rank || '-'}}</div>
							</div>
						</div>
						<div class="action college-tr-mask-white" style="width: 8.9%;">
							<div><button class="yzy-button mini yzy-new-button  szmain" :class="{'selected':isItemSelected(item)}" @click="addVolunteer(item)">
									{{isItemSelected(item)?'已填报&nbsp;':'填报&nbsp;'}}
								</button>
							</div>
						</div>
					</div>
				</div>
				<div class="pagination">
					<el-pagination
						v-model:currentPage="currentPage"
						v-model:pageSize="pageSize"
						:small="false"
						prev-text="上一页"
						next-text="下一页"
						layout="prev, pager, next, jumper"
						:total="total"
						@current-change="handleCurrentPageChange"
					/>
				</div>
			</div>

		</div>


		<!-- 悬浮框 -->
		<div id="tzy-selected-pop" :class="rightShow?'slide-out':''" cart-list="">
			<div class="shoppingCarLeft pull-left">
				<div class="foldBtn" @click="pullleftClick">
					<p class="f16">{{rightShow?'收起':'展开'}}志愿表</p> <span class="f18">{{volunteer_data.length}}</span>
				</div>
				<button class="yzy-button save" @click="saveVolunteer">保存</button>
				<button class="yzy-button save safe" style="border-top: 0px;" @click="topClick">顶部</button>
			</div>
			<div class="shoppingCarRight pull-right" style="height: 799px;">
				<!-- <div class="searchInput el-input el-input--suffix"><input type="text" autocomplete="off"
						placeholder="院校/专业搜索" class="el-input__inner"><span class="el-input__suffix"><span
							class="el-input__suffix-inner"><i class="el-input__icon el-icon-search f16"
								style="cursor: pointer;"></i></span></span>
				</div> -->
				<div  class="cartList" style="height: 669px;">
					<div  class="cartListItem" v-for="(item,index) in volunteer_data">
						<div  class="cartCollege">
							<div  class="college-title dataType pull-left chong"><span
									 class="college-num">{{index + 1}}</span></div> <span 
								class="cartCollegeName">{{ item.name }} {{ item.code }}</span> 
								<img style="cursor:pointer;margin-left:auto;" src="../../assets/img/del.png" alt="" @click="deleteSpecial(index)"/>
						</div>
						<div  class="professionItem">
							<p  class="professionName ml30"><span >{{ item.special_name }} {{ item.special_code }}</span>
							</p>
						</div>
					</div>
				</div>
				<button class="yzy-button creatsuccess" @click="saveVolunteer">
					<p>保存志愿表</p> <span>( 已填{{volunteer_data.length}}个志愿 )</span>
				</button>
			</div>
		</div>



	</div>
</template>

<script>
	import { storage } from '@/utils/storage';
	import {
		getCondition,
		getDataList,
		saveVolunteer
	} from "@/api/home";
	export default {
		components: {},

		data() {
			return {
				category_id: '', //批次id
				year: '', //批次数据年份
				title: '', //批次名称

				currentPage: 1,
				pageSize: 10,
				dataList: [],
				total: 0,

				/* 筛选 */
				keyword:'',

				collegeData: ['公办','民办','综合','985','211','双一流','一流大学建设A类'],
				college: [],
				college_str: '',

				cityData: [],
				city: [],
				city_str: '',

				plan_min: '',
				plan_max: '',

				score_min: '',
				score_max: '',

				tuition_min: '',
				tuition_max: '',

				riskData: [],
				risk: '',

				slidervalue: [600, 400],//滑动条的值

				volunteer_id: '',
				volunteer_data: [],

				rightShow: false, //右侧弹框展示与隐藏

			};
		},
		created() {},
		beforeRouteEnter(to, from, next) {
			// 在渲染该组件的对应路由被 confirm 前调用
			// 不！能！访问组件实例 `this`
			// 因为当守卫执行前，组件实例还没被创建
			next(vm => {
			// 通过 `vm` 访问组件实例
			const volunteer_id = to.query.volunteer_id;
				if(volunteer_id){
					vm.loadVolunteerData(volunteer_id);
				}
			});
		},
		mounted() {
			this.category_id = this.$route.query.id;
			this.year = this.$route.query.year;
			this.title = this.$route.query.title;
			this.getCondition();
			this.getDataList();
		},
		watch: {
			'$route'(to,form) {
				if(to.name === 'collegeSpecial') {
					this.resetCondition();
					
					if(to.query.volunteer_id){
						this.loadVolunteerData(to.query.volunteer_id);
					}else{
						this.volunteer_id = '';
						this.volunteer_data = [];
					}
				}
			}
		},
		methods: {
			resetCondition(){
				this.category_id = this.$route.query.id;
				this.year = this.$route.query.year;
				this.title = this.$route.query.title;
				this.currentPage = 1; // 重置当前页码为第一页
				this.keyword = '';
				this.college = [];
				this.college_str = '';
				this.city = [];
				this.city_str = '';
				this.plan_min = '';
				this.plan_max = '';
				this.score_min = '';
				this.score_max = '';
				this.tuition_min = '';
				this.tuition_max = '';
				this.risk = '';
				this.getCondition();
				this.getDataList(); // 获取数据列表
			},
			loadVolunteerData(volunteer_id) {
				if (volunteer_id) {
					let volunteer = storage.get('volunteer_' + volunteer_id);
					if (volunteer) {
					this.volunteer_id = volunteer_id;
					this.volunteer_data = JSON.parse(volunteer);
					}
				}
			},
			handleTop(){
				window.scrollTo({
					top: 0,
					behavior: 'smooth' // 可选的，使用平滑滚动效果
				});
			},
			handleCurrentPageChange(newPage){
				this.currentPage = newPage;
				this.getDataList();
				this.handleTop();
			},
			/* 获取筛选条件 */
			async getCondition(){
				try {
					const res = await getCondition({
						category_id: this.category_id,
					});

					if (res.code === 1) {
						let data = res.data;
						this.cityData = data.city;
						this.tuitionData = data.tuition;
						this.riskData = data.risk_level;
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},
			/* 获取数据列表 */
			async getDataList(){
				try {
					const res = await getDataList({ 
						category_id: this.category_id,
						page: this.currentPage,
						rows: this.pageSize,

						key: this.keyword,
						college_label: this.college_str,
						tuition_min: this.tuition_min,
						tuition_max: this.tuition_max,
						city: this.city_str,
						risk: this.risk,
						subject_review: this.estimate_str,
						plan_min: this.plan_min,
						plan_max: this.plan_max,
						score_min: this.score_min,
						score_max: this.score_max,
					});

					if (res.code === 1) {
						let data = res.data;
						this.dataList = data.list;
          				this.total = data.total;
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},


			/* 筛选条件 */
			setKeyWord(e){
				this.keyword = e;
			},
			search() {
				this.currentPage = 1;
				this.dataList = [];
				this.total = 0;
				this.getDataList();
			},
			//城市
			setCity(item) {
				if (this.city.includes(item)) {
					this.city = this.city.filter(function(ele) {
						return ele != item;
					});
				} else {
					this.city.push(item);
				}
				this.city_str = this.city.join(',');
				this.search();
			},
			//院校
			setCollege(item) {
				var that = this;
				if (that.college.includes(item)) {
					that.college = that.college.filter(function(ele) {
						return ele != item;
					});
				} else {
					that.college.push(item);
				}
				this.college_str = this.college.join(',');
				this.search();
			},
			setPlanMin(e){
				this.plan_min = e;
				this.search();
			},
			setPlanMax(e){
				this.plan_max = e;
				this.search();
			},
			setScoreMin(e){
				this.score_min = e;
				this.search();
			},
			setScoreMax(e){
				this.score_max = e;
				this.search();
			},
			setTuitionMin(e){
				this.tuition_min = e;
				this.search();
			},
			setTuitionMax(e){
				this.tuition_max = e;
				this.search();
			},
			setRisk(index) {
				if(index == (this.risk - 1)){
					this.risk = '';
					this.search();
					return
				}
				this.risk = index + 1;
				this.search();
			},
			//填报相关
			isItemSelected(item) {
				return this.volunteer_data.some(volunteer => volunteer.id === item.id);
			},
			addVolunteer(item){
				// 使用 findIndex 查找是否有 id 匹配的 JSON 对象
				const index = this.volunteer_data.findIndex(volunteer => volunteer.id === item.id);

				if (index !== -1) {
					// 如果找到了匹配的 JSON 对象，从数组中删除它
					this.volunteer_data.splice(index, 1);
				} else {
					// 如果没有找到匹配的 JSON 对象，把新的 JSON 对象 push 到数组中
					this.volunteer_data.push({
						id: item.id,
						name: item.college_name,
						code: item.college_code,
						special_name: item.special_name,
						special_code: item.special_code,
						risk_percentage: item.risk_percentage,
						risk_name: item.risk_name,
						predictive_score: item.predictive_score,
						m_score: item.m_score,
					});
				}
					
			},
			deleteSpecial(index) {
				// 根据传入的索引直接删除对应的志愿组
				this.volunteer_data.splice(index, 1);
			},
			async saveVolunteer() {
				try {

					const volunteer = this.volunteer_data;

					if (volunteer.length === 0) {
						this.$message.error('请选择志愿');
						return;
					}

					const volunteer_data = volunteer.map(item => ({
						id: item.id,
						risk_percentage: item.risk_percentage,
						risk_name: item.risk_name,
						predictive_score: item.predictive_score,
						m_score:item.m_score
					}));
					let requestData;
					if(this.volunteer_id){
						requestData = {
							id: this.volunteer_id,
							content: JSON.stringify(volunteer_data),
						}
					}else{
						requestData = {
							year: this.year,
							batch_id: this.category_id,
							batch_name: this.title,
							content: JSON.stringify(volunteer_data),
							type: 2
						}
					}
					const res = await saveVolunteer(requestData);

					if (res.code === 1) {
						const id = res.data;
						this.$message.success('保存成功');
						// const route = this.$router.resolve({
						// 	name: 'alreadyVol',
						// 	params: { id: id }
						// })
						// window.open(route.href, '_blank');
						let route = {
							name: 'alreadyVol', // 使用路由名称进行跳转
							query: { // 使用params传递参数时，参数不会显示在URL中
								id: id,
							}
						};
						// 使用Vue Router进行页面跳转
						this.$router.push(route);
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},

			toCollege(id) {
				// 构建带有查询参数的路由路径
				const baseUrl = this.$router.resolve({ name: 'schoolInfo' }).href;
				const queryParams = `?id=${encodeURIComponent(id)}`;
				const fullUrl = `${baseUrl}${queryParams}`;

				// 在新窗口打开带有查询参数的路径
				window.open(fullUrl, '_blank');
			},




			recommendClick(){
				let routeUrl = this.$router.resolve({
				        path: '/recommend',// 这里的路径就可以正常的写，不需要添加_blank: true
				        // query:{Index:i}
				      })
				window.open(routeUrl.href, '_blank')
			},
			formatTooltip() {
				this.$nextTick(() => {
					this.$refs.slider.setPosition(this.slidervalue)
				})
			},
			//点击右侧悬浮框
			pullleftClick() {
				this.rightShow = !this.rightShow
			},
			// 页面返回到顶部
			topClick() {
				const scrollToTop = setInterval(() => {
					const currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
					if (currentPosition > 0) {
						window.requestAnimationFrame(() => {
							window.scrollTo(0, currentPosition - currentPosition / 8);
						});
					} else {
						clearInterval(scrollToTop);
					}
				}, 16); // 16ms 是一个合理的帧率间隔，可以保持动画的流畅
			}

		},
	};
</script>
<style scoped lang="scss" src="./index.scss" />
<style lang="scss" scoped>
	.home_box {
		min-height: 100vh;
		padding: 30px;
		max-width: 1940px;
		margin: 0 auto;
		background-image: linear-gradient(to bottom, #FFE7DF, #F9F9F9 80%);
		background-repeat: no-repeat;
		background-color: #F9F9F9 !important;
		// .home_top{
		// 	display: -webkit-box;
		// 	display: -webkit-flex;
		// 	display: -ms-flexbox;
		// 	display: flex;
		// 	-webkit-box-pack: justify;
		// 	-webkit-justify-content: space-between;
		// 	-ms-flex-pack: justify;
		// 	align-items: center;
		// 	.home_top_right{
		// 		width: 160px;
		// 		height: 40px;
		// 		border-radius: 10px;
		// 		background: linear-gradient(270deg, #F36932 0%, #E6200C 100%), #FFEFE9;
		// 		display: flex;
		// 		align-items: center;
		// 		justify-content: center;
		// 		font-size: 18px;
		// 		font-weight: 500;
		// 		color: #fff;
		// 	}
		// }
	}

	/deep/.el-slider__bar {
		background-color: #F0673A;
	}

	/deep/.el-slider__button {
		border: 2px solid #F0673A;
	}
	.buttom_div {
		display: block;
	}
	.pagination{
		display: flex;
		justify-content: center;
		margin-top: 40px;
	}
</style>