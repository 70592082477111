<template>
	<div class="home_box">
		<!-- <div class="home_top">
		  <div class="home_top_left">
			  <img src="../../assets/让学习点燃新希望_01.png" alt="" srcset="" />
		  </div>
		  <div class="home_top_right">
			  注册/登录
		  </div>
	  </div> -->

		<div class="vol-main">
			<div class="A_main_notice">
				<div class="A_main_notice_tit"><span>!</span>重要提示</div>
				<ul>
					<li> 注:以上志愿表为模拟埴报志愿表，正式填报请登录省考试院指定报网站;</li>
					<li> 申明:智能推荐使用当年招生计划和参考历年录取数据的志愿模拟填报平台</li>
					<li>参考数据均来自省考试院，正式填报过程中如遇到数据错误，请参阅省考试院发布的相关招生计划书籍或拨打客服热线。</li>
				</ul>
			</div>
			<div class="vol-cen">
				<div class="alreadyvol-top">
					<div class="alr-p1">
						{{volunteer.name}} 
						<!-- <span>全部志愿表</span> -->
					</div>
					<div class="alr-p2">
						<div class="alr-p2-l">
							<div class="alr-p2-ll">{{volunteer.province}} {{volunteer.candidate_type}} {{volunteer.optional_str}} {{volunteer.cultural_score}}</div>
							<div class="alr-p2-lr">
								<p><span class="span1"></span>冲({{risk_num.sprint}})</p>
								<p><span class="span2"></span>稳({{risk_num.steady}})</p>
								<p><span class="span3"></span>保({{risk_num.defend}})</p>
							</div>
						</div>
						<div class="alr-p2-r">
							<!-- <div><img src="../../assets/img/px.png" alt="" />一键排序</div> -->
							<!-- <el-button>返回</el-button> -->
							<el-button @click="saveVolunteer">保存</el-button>
							<el-button v-if="is_update" @click="updateVolunteer">修改</el-button>
							<el-button @click="downloadExcel">下载</el-button>
							<!-- <el-button @click="printClick">打印</el-button> -->
						</div>
					</div>
				</div>
				<div style="height: 48px;">
					<div class="tzy-container-th">
						<div class="pl20" style="width: 6%;">
							排序
							
						</div>
						<div class="pl20" style="width: 6%;">
							录取概率
							
						</div>
						<!-- <div style="width: 15%; text-align: left; padding-left: 20px;">
							专业
				
						</div> -->
						<div style="width: 33.1%; text-align: left; padding-left: 20px;">
							院校组/专业
							
						</div>
						<div style="width: 11.2%;">
							{{volunteer.year}}年招生计划
							
						</div>
						<div class="tzy-history-tr" style="width: 36.8%;">
							<div class="tzy-container-td" style="text-align:center">历年</div>
						</div>
						<div style="width: 6.9%; text-align: left; padding-left: 20px;">
							操作
						</div>
					</div>
				</div>
				<div class="" v-for="(item,index) in list">
					<div class="tzy-college-tr">
						<div class="percent" style="width: 6%;">
							<div class="percentnumpx"style="position: relative;">
								<div style="font-size: 26px;color: #000;">{{ index + 1 }}</div>
								<div style="position: relative;">
									<div class="el-icon-arrow-up up" @click="moveItemUp(index)"></div>
									<div class="el-icon-arrow-down up" @click="moveItemDown(index)"></div>
								</div>
							</div>
						</div>
						
						<div class="percent" style="width: 6%;">
							<h1 class="percent-num">
								<span>{{item.risk_percentage}}</span>
								<span style="font-size: 12px;">%</span>
							</h1>
							<p class="ifrush A">
								<span :class="[item.risk_name == '冲'?'span1':'',item.risk_name == '稳'?'span2':'',item.risk_name == '保'?'span3':'']">{{item.risk_name}}</span>
							</p>
							<!-- <p class="">预测分：{{item.group_predictive_score}}</p> -->
						</div>
						<!-- <div class="major" style="width: 15%; text-align: left;">
							<div class="major-info">
								<a class="major-name"><span>安全工程</span></a>
								<div class="remark one">(呈贡校区)</div>
							</div>
							<p class="major-intr"> 代码： <span class="code">B7</span> </p>
						</div> -->
						<div class="college" style="width: 33.1%; text-align: left;">
							<div class="college-info">
								<div>
									<a href="javascript:void(0)" class="college-name" @click="toCollege(item.college_id)">
										<span>{{item.college_group}}</span>
									</a>
									<div class="remark">
										{{item.line_level}} {{item.province}} {{item.city}}
									</div>
								</div>
							</div>
							<div class="college-intr">
								<p> 代码：
									<span class="code"> {{item.college_group_code}} </span>
									<span v-if="item.tag">
										<span class="split">|</span>
										<span v-for="(t,i) in item.tag" :key="i"> {{i != 0 ? ' / ' + t : t}}</span>
									</span>
									<!-- <span>
										<span class="split">|</span>
										新疆 / 克拉玛依市 / 理工 /
										公办 / 排名 86
									</span> -->
								</p>
							</div>
						</div>
						<div class="plan" style="width: 11.2%;">
							<span class="plan-num"
								style="text-rendering: optimizelegibility; font-feature-settings: &quot;kern&quot;; font-kerning: normal;">
								{{item.group_plan_num || '-'}} <span style="font-size: 12px;">人</span>
							</span>
							<!-- <p><span class="learn-year">
									4年&nbsp;&nbsp;￥3500
								</span> <br> <span class="choose-subject">
									物 和 化
								</span> <span></span></p> -->
						</div>
						<div class="tzy-history-tr" default-selected="理科" style="width: 36.8%;">
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col"><span>录取人数</span></div>
								<div class="tzy-container-td-col"><span>计划人数</span></div>
								<div class="tzy-container-td-col"><span>最低分</span>
								</div>
								<div class="tzy-container-td-col"><span>最低位次</span></div>
						
							</div>
							<div class="tzy-container-td solid" v-for="t in item.score_list">
								<div class="tzy-container-td-col">{{t.year || '-'}}</div>
								<div class="tzy-container-td-col">{{t.group_plan_num || '-'}}</div>
								<div class="tzy-container-td-col">{{t.group_low_score || '-'}}</div>
								<div class="tzy-container-td-col">{{t.group_low_rank || '-'}}</div>
							</div>
						</div>
						<div class="action college-tr-mask-white" style="width: 6.9%;">
							
								<!-- <div  class="actionRight">
									<div class="actionRightnum">1</div>
									<button class="yzy-button mini yzy-new-button  szmain " @click="onsubmitClick(index,3)">
										专业3<i  :class="item.open?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
									</button>
								</div> -->
								<div class="rightyd">
									<!-- <img src="../../assets/img/shou.png" alt="" /> -->
									<img src="../../assets/img/del.png" alt="" @click="removeItem(index)"/>
								</div>
							<!-- <div v-else>
								<button class="yzy-button mini yzy-new-button  szmain " >
									填报&nbsp;
								</button>
							</div> -->
						</div>
					</div>
					
					
					<div class="tzy-college-tr" v-for="(v,k) in item.list">
						<div class="percent" style="width: 6%;">
							<div class="percentnumpx"style="position: relative;">
								<div style="font-size: 26px;color: #000;">{{ index + 1 }}-{{ k + 1 }}</div>
								<div style="position: relative;">
									<div class="el-icon-arrow-up up" @click="moveSpecialUp(index,k)"></div>
									<div class="el-icon-arrow-down up" @click="moveSpecialDown(index,k)"></div>
								</div>
							</div>
						</div>
						<div class="percent" style="width: 6%;justify-content: center;">
							<!-- <h1 class="percent-num">
								<span>1</span>
								<span style="font-size: 12px;">%</span>
							</h1> -->
							<!-- <p class="percent-text">极大风险</p> -->
							<!-- <p class="ifrush A">
								<span class="span1">冲</span>
								<span class="span2">冲</span>
								<span class="span3">冲</span>
							</p> -->
							<p class="">预测分：{{v.predictive_score || '-'}}</p>
						</div>
						<!-- <div class="major" style="width: 15%; text-align: left;">
							<div class="major-info">
								<a class="major-name"><span>安全工程</span></a>
								<div class="remark one">(呈贡校区)</div>
							</div>
							<p class="major-intr"> 代码： <span class="code">B7</span> </p>
						</div> -->
						<div class="college" style="width: 33.1%; text-align: left;">
							<div class="college-info">
								<div>
									<a href="javascript:void(0)" class="college-name">
										<span>{{v.special_name}}</span></a>
									<div class="remark">
										{{v.special_remark}}
									</div>
								</div>
							</div>
							<div class="college-intr">
								<p> 代码：
									<span class="code"> {{v.special_code}} </span>
								</p>
							</div>
						</div>
						<div class="plan" style="width: 11.2%;display: flex;flex-direction: column;">
							<span class="plan-num"
								style="text-rendering: optimizelegibility; font-feature-settings: &quot;kern&quot;; font-kerning: normal;">
								{{v.plan_num}} <span style="font-size: 12px;">人</span>
							</span>
							<p style="display: flex;flex-direction:column;align-items: center;">
								<span v-if="v.edu_year">
									{{v.edu_year}}年
								</span>
								<span class="learn-year">
									￥{{v.tuition}}
								</span>
								<span class="choose-subject">
									{{v.optional}}
								</span>
							</p>
						</div>
						<div class="tzy-history-tr" default-selected="理科" style="width: 36.8%;">
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col"><span>年份</span></div>
								<!-- <div class="tzy-container-td-col"><span>录取人数</span></div>
								<div class="tzy-container-td-col"><span>计划人数</span></div> -->
								<div class="tzy-container-td-col"><span>最低分</span>
								</div>
								<div class="tzy-container-td-col"><span>最低位次</span></div>
							</div>
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col">{{v.year || '-'}}</div>
								<div class="tzy-container-td-col">{{v.training_low_score || '-'}}</div>
								<div class="tzy-container-td-col">{{v.training_low_rank || '-'}}</div>
							</div>
							<div class="tzy-container-td solid">
								<div class="tzy-container-td-col">{{v.parent_year || '-'}}</div>
								<div class="tzy-container-td-col">{{v.parent_score || '-'}}</div>
								<div class="tzy-container-td-col">{{v.parent_rank || '-'}}</div>
							</div>
						</div>
						<div class="action college-tr-mask-white" style="width: 6.9%;">
							<!-- <div v-if="item.chui!=1">
								<div  class="actionRight">
									<div class="actionRightnum">1</div>
									<button class="yzy-button mini yzy-new-button  szmain " @click="onsubmitClick(index,3)">
										3个专业&nbsp;<i  :class="item.open?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
									</button>
								</div>
								<div class="seleyt">已填2</div>
							</div> -->
							<div class="rightyd">
								<!-- <img src="../../assets/img/shou.png" alt="" /> -->
								<img src="../../assets/img/del.png" alt="" @click="removeSpecialElement(index,k)"/>
							</div>
						</div>
					</div>
				</div>






			</div>

		</div>


		<!-- 悬浮框 -->
		<!-- <div id="tzy-selected-pop" :class="rightShow?'slide-out':''" cart-list="">
			<div class="shoppingCarLeft pull-left">
				<div class="foldBtn" @click="pullleftClick">
					<p class="f16">{{rightShow?'收起':'展开'}}志愿表</p> <span class="f18">0</span>
				</div>
				<button class="yzy-button save">保存</button>
				<button class="yzy-button save safe" style="border-top: 0px;" @click="topClick">顶部</button>
			</div>
			<div class="shoppingCarRight pull-right" style="height: 799px;">
				<div class="searchInput el-input el-input--suffix"><input type="text" autocomplete="off"
						placeholder="院校/专业搜索" class="el-input__inner"><span class="el-input__suffix"><span
							class="el-input__suffix-inner"><i class="el-input__icon el-icon-search f16"
								style="cursor: pointer;"></i></span></span>
				</div>
				<div class="cartList" style="height: 669px;">
					<div class="cartListItem">
						<div class="cartCollege">
							<div class="college-title dataType pull-left chong"><span class="college-num">1</span></div>
							<span class="cartCollegeName">中国石油大学(北京)克拉玛依校区(新疆公办)</span>
							<img src="../../assets/img/del.png" alt="" />
						</div>
						<div class="professionItem">
							<p class="professionName ml30"><span>安全工程</span>
							</p>
						</div>
					</div>
				</div>
				<button class="yzy-button creatsuccess">
					<p>保存志愿表</p> <span>( 已填0个志愿 )</span>
				</button>
			</div>
		</div> -->



	</div>
</template>

<script>
	import { storage } from '@/utils/storage';
	import {
		getVolunteerList,
		saveVolunteer,
		downloadExcel
	} from "@/api/home";
	export default {
		components: {},

		data() {
			return {
				id:0,
				is_update:0,
				list:[],
				risk_num:{sprint: 0, defend: 0, steady: 0},
				volunteer:{},
			};
		},
		created() {
			if (this.$route.query.id) {
				this.getVolunteerList();
			}
		},
		mounted() {
			
		},
		watch: { 
			'$route' (to, from) { 
				if (to.query.id && to.name === 'volTwoAlreadyVol') {
					console.log("检测到id参数，刷新列表");
					this.getVolunteerList();
				}
			}
		},
		methods: {
			async getVolunteerList() {
				this.id = this.$route.query.id;
				try {
					const res = await getVolunteerList({
						id:this.id,
					});

					if (res.code === 1) {
						const data = res.data;
						this.is_update = data['is_update'];
						this.list = data['list'];
						this.risk_num = data['risk_num'];
						this.volunteer = data['volunteer'];
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},
			moveItemUp(index) {
				if (index === 0) return; // 已经是第一个元素，无法上移
				const temp = this.list.splice(index, 1)[0]; // 从当前位置移除元素
				this.list.splice(index - 1, 0, temp); // 在前一个位置插入元素
			},
			moveItemDown(index) {
				const list = this.list;
				if (index === list.length - 1) return; // 已经是最后一个元素，无法下移
				const temp = list.splice(index, 1)[0]; // 从当前位置移除元素
				list.splice(index + 1, 0, temp); // 在后一个位置插入元素
			},
			removeItem(index) {
				if (index < 0 || index >= this.list.length) return; // 索引无效，不在数组范围内
				this.list.splice(index, 1); // 从数组中删除指定索引的元素
			},
			moveSpecialUp(group_index, special_index) {
				const groupList = this.list;
				if (group_index < 0 || group_index >= groupList.length) return;
				const specialList = groupList[group_index].list;
				if (special_index <= 0 || special_index >= specialList.length) return;
				
				const temp = specialList.splice(special_index, 1)[0];
				specialList.splice(special_index - 1, 0, temp);
			},
			moveSpecialDown(group_index, special_index) {
				const groupList = this.list;
				if (group_index < 0 || group_index >= groupList.length) return;
				const specialList = groupList[group_index].list;
				if (special_index < 0 || special_index >= specialList.length - 1) return;
				
				const temp = specialList.splice(special_index, 1)[0];
				specialList.splice(special_index + 1, 0, temp);
			},
			removeSpecialElement(group_index, special_index) {
				const groupList = this.list;
				if (group_index < 0 || group_index >= groupList.length) return; // 检查group_index是否有效
				const specialList = groupList[group_index].list;
				if (special_index < 0 || special_index >= specialList.length) return; // 检查special_index是否有效

				// 删除special_index位置的元素
				specialList.splice(special_index, 1);

				// 检查二级列表是否为空，如果为空则删除一级列表中的对应元素
				if (specialList.length === 0) {
					groupList.splice(group_index, 1);
				}
				// console.log(groupList)
			},
			async saveVolunteer() {
				try {
					const result = this.processingData();
					if(result.length == 0){
						this.$message.error('志愿不能为空');
						return;
				};
					const res = await saveVolunteer({
						id: this.id,
						content: JSON.stringify(result),
					});

					if (res.code === 1) {
						this.$message.success('保存成功');
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},
			updateVolunteer(){
				let volunteer = this.volunteer;
				let userInfo = storage.get('userInfo');;
				// 提取要比较的字段
				let volunteerFields = {
					province: volunteer.province,
					candidate_type: volunteer.candidate_type,
					optional: volunteer.optional,
					cultural_score: volunteer.cultural_score,
					art_score: volunteer.art_score
				};

				let userInfoFields = {
					province: userInfo.province,
					candidate_type: userInfo.candidate_type,
					optional: userInfo.optional,
					cultural_score: userInfo.cultural_score,
					art_score: userInfo.art_score
				};

				// 比较字段是否完全一致
				let areFieldsIdentical = Object.keys(volunteerFields).every(key => {
					return volunteerFields[key] == userInfoFields[key];
				});
				// 如果所有字段都匹配，则调用processingData函数
				if (areFieldsIdentical) {
					let result = this.processingData(1); // 假设processingData是此组件中的方法
					storage.set('volunteer_'+this.id, JSON.stringify(result));
					// 构造路由对象，带参数
					let route = {
						name: 'collegeSpecialGroup', // 使用路由名称进行跳转
						query: { // 使用params传递参数时，参数不会显示在URL中
							id: volunteer.batch_id,
							year: volunteer.year,
							title: volunteer.batch_name,
							volunteer_id: this.id
						}
					};
					
					// 使用Vue Router进行页面跳转
					this.$router.push(route);
				} else {
					// 字段不匹配时的处理逻辑，可根据需要添加
					this.$message.error('无法修改');
					this.is_update = 0;
				}
			},
			processingData(type = 0){
				const result = [];

				this.list.forEach(collegeObj => {
					const collegeName = collegeObj.college_group;
					const collegeCode = collegeObj.college_group_code;
					const riskPercentage = collegeObj.risk_percentage; // 直接从原始数据中获取
					const riskName = collegeObj.risk_name; // 直接从原始数据中获取

					const specialList = [];
					collegeObj.list.forEach(specialObj => {
						const specialItem = {
							id: specialObj.id,
							predictive_score: specialObj.predictive_score,
							m_score: specialObj.m_score,
							// 可以继续添加其他需要的二级结构字段
						};
						if (type === 1) {
							specialItem.special_name = specialObj.special_name;
							specialItem.special_code = specialObj.special_code;
						}
						specialList.push(specialItem);
					});

					const collegeItem = {
						name: collegeName,
						code: collegeCode,
						risk_percentage: riskPercentage, // 添加到结果中
						risk_name: riskName, // 添加到结果中
						list: specialList
					};

					result.push(collegeItem);
				});
				return result;
			},
			async downloadExcel(){
				try {
					const res = await downloadExcel({
						id: this.id,
					});

					if (res.code === 1) {
						window.location.href = res.data;
					} else {
						this.$message.error(res.msg);
					}
				} catch (error) {
					this.$message.error('请求失败');
				}
			},
			toCollege(id) {
				// 构建带有查询参数的路由路径
				const baseUrl = this.$router.resolve({ name: 'schoolInfo' }).href;
				const queryParams = `?id=${encodeURIComponent(id)}`;
				const fullUrl = `${baseUrl}${queryParams}`;

				// 在新窗口打开带有查询参数的路径
				window.open(fullUrl, '_blank');
			},


			// 打印
			printClick(){
				// this.$router.push("/volTwoPrint");
				
				
				let routeUrl = this.$router.resolve({
				        path: '/volTwoPrint',// 这里的路径就可以正常的写，不需要添加_blank: true
				        // query:{Index:i}
				      })
				window.open(routeUrl.href, '_blank')
			},
			formatTooltip() {
				this.$nextTick(() => {
					this.$refs.slider.setPosition(this.slidervalue)
				})
			},
			//点击右侧悬浮框
			pullleftClick() {
				this.rightShow = !this.rightShow
			},
			// 页面返回到顶部
			topClick() {
				const scrollToTop = setInterval(() => {
					const currentPosition = document.documentElement.scrollTop || document.body.scrollTop;
					if (currentPosition > 0) {
						window.requestAnimationFrame(() => {
							window.scrollTo(0, currentPosition - currentPosition / 8);
						});
					} else {
						clearInterval(scrollToTop);
					}
				}, 16); // 16ms 是一个合理的帧率间隔，可以保持动画的流畅
			}

		},
	};
</script>
<style scoped lang="scss" src="./index.scss" />
<style lang="scss" scoped>
	// 上面引入的css 只是表格样式
	
	//新增s
	
	.alreadyvol-top{
		margin-bottom: 20px;
		.alr-p1{
			display: flex;
			align-items: center;
			font-size: 19px;
			font-weight: bold;
			span{
				color: #FF0000;
				font-size: 14px;
				margin-left: 10px;
			}
		}
		.alr-p2{
			display: -webkit-box;
			display: -webkit-flex;
			display: -ms-flexbox;
			display: flex;
			-webkit-box-pack: justify;
			-webkit-justify-content: space-between;
			-ms-flex-pack: justify;
			align-items: center;
			.alr-p2-l{
				display: flex;
				font-size: 16px;
				align-items: center;
				.alr-p2-ll{
					margin-right: 50px;
				}
				.alr-p2-lr{
					display: flex;
					align-items: center;
					p{
						display: flex;
						align-items: center;
						margin-right: 30px;
						span{
							display: block;
							width: 14px;
							height: 14px;
							color: #FF0000;
							border-radius: 50%;
						}
						.span1{
							background-color:#FF0000;
						}
						.span2{
							background-color:#FF9423;
						}
						.span3{
							background-color:#00B578;
						}
						
					}
				}
			}
			.alr-p2-r{
				display: flex;font-size: 16px;
				align-items: center;
				div{
					margin-right: 30px;
				}
				img{
					width: 15px;
					height: 15px;
					margin-right: 6px;
				}
			}
		}
	}
	
	//新增e
	
	
	
	.home_box {
		min-height: 100vh;
		padding: 30px;
		max-width: 1940px;
		margin: 0 auto;
		background-image: linear-gradient(to bottom, #FFE7DF, #F9F9F9 80%);
		background-repeat: no-repeat;
		background-color: #F9F9F9 !important;
		
	}

	/deep/.el-slider__bar {
		background-color: #F0673A;
	}

	/deep/.el-slider__button {
		border: 2px solid #F0673A;
	}
	// 填报须知
	.A_main_notice{
		border-radius: 10px;
		background: rgba(0, 0, 0, 0.04);
		box-sizing: border-box;
		border: 1px dashed #FF0000;
		padding: 15px;
		.A_main_notice_tit{
			font-weight: bold;
			font-size: 16px;
			display: flex;
			align-items: center;
			margin-bottom: 6px;
			span{
				display: block;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				background-color: red;
				color: #fff;
				display: flex;
				justify-content: center;
				align-items: center;
				margin-right: 6px;
				font-size: 14px;
			}
		}
		ul{
			li{
				font-size: 14px;
				margin-left: 20px;
			}
		}
	}
	.buttom_div {
		   display: block;
		}
</style>