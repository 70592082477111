import axios from 'axios'
import store from '@/store'
import router from '@/router'
import element from 'element-ui';
import { storage } from '@/utils/storage';

// 创建axios实例。统一配置
const service = axios.create({
  baseURL: 'https://data1.05hou.com/', // api的base_url
  // baseURL: 'https://admin.m.sdgyyc.com/', // api的base_url
  timeout: 30000 // 请求超时时间
  // .... 其他信息
})
const service2 = axios.create({
  baseURL: 'https://data2.05hou.com/', // api的base_url
  timeout: 30000 // 请求超时时间
  // .... 其他信息
})
// request拦截器
service.interceptors.request.use(
  (config) => {
      config.headers = config.headers || {}
      config.headers.token = storage.get('token') || ''
      return config;
  },
  (error) => {
      Promise.reject(error);
  }
);

// respone拦截器
service.interceptors.response.use(
  async (res) => {
      const code = res.code
      // if (code === 401) {
      //     notification('登录过期，请重新登录')
      //     router.push({ path: '/home' })
      //     return Promise.reject(res.data)
      // }

      return res.data
  },
  (error) => {
      if (error.response.status == 401) {
        // element.Message.error('登录过期，请重新登录')
        store.dispatch('user/logout');

        router.push({ path: '/' })

      }
      return Promise.reject(error);
  }
);

// request拦截器
service2.interceptors.request.use(config => {
  //... 获取token，存储token 等操作
  return config
}, error => {
  // console.log(error)
  Promise.reject(error)
})

// respone拦截器
service2.interceptors.response.use(
  response => {
      return response.data
  },
  error => {
    return Promise.reject(error)
  }
)
export { service, service2 };
